<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    transition="dialog-top-transition"
  >
    <template #activator="{ on }">
      <v-btn :disabled="disabled" color="success" v-on="on">
        {{ $t("save") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ $t("paymentterm.customers.apply") }}
      </v-card-title>

      <v-card-text>
        {{ $t("paymentterm.customers.applyinfo") }}
      </v-card-text>

      <v-card-text class="text-center">
        <v-btn-toggle v-model="applyTo" color="primary" rounded>
          <v-btn small value="customer">{{ $t("customer") }}</v-btn>
          <v-btn small value="provider" v-text="$t('provider')" />
          <v-btn small value="both" v-text="$t('both')" />
          <v-btn :value="null" small v-text="$t('none')" />
        </v-btn-toggle>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="green darken-1" text @click="onSubmit">
          {{ $t("continue") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PaymentTermsConfirmDialog extends Vue {
  @Prop(Boolean) readonly disabled!: boolean;

  dialog = false;
  applyTo: "customer" | "provider" | "both" | null = null;

  onSubmit() {
    this.$emit("apply", this.applyTo);
    this.dialog = false;
  }
}
</script>
